
import React, { useState } from 'react';
import axios from 'axios';
import './ExcelUploader.css';

const UploadExcel = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadStatus(null); 
  };

  const handleUpload = async () => {
    if (!file) {
      console.error('Please choose a file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}/api/v1/tweets/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);
      setUploadStatus({ success: true, message: 'File uploaded successfully' });
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadStatus({ success: false, message: 'Error uploading file. Please try again.' });
    }
  };

  return (
    <div className='excel-uploader-container'>
      <div className='center-box'>
        <div className='heading'>
          <h1 style={{ color: '#3a59d5' }}>Upload Excel File</h1>
        </div>
  
        <div>
          <input type="file" className="file-input" onChange={handleFileChange} />
          <button className="upload-button" onClick={handleUpload}>
            Upload
          </button>
  
          {uploadStatus && (
            <div className={uploadStatus.success ? 'success-msg' : 'error-msg'} style={{ color: '#3a59d5' }}>
              {uploadStatus.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
  }

export default UploadExcel;