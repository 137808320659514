

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import UploadExcel from './ExcelUpload';
import NavigationBar from './Navbar';
import TweetTable from './tweetTable';
import TrendingHashtags from './treandingTags';
import EditTweet from './editform';
import Login from './Login';

const App = () => {
  const [loggedInUser, setLoggedInUser] = useState(null);

  const handleLogin = (user) => {
    setLoggedInUser(user);
  };

  const handleLogout = () => {
    setLoggedInUser(null);
  };

  return (
    <Router>
      <div>
        {loggedInUser && <NavigationBar onLogout={handleLogout} />} {/* Pass onLogout function */}
        <Routes>
          <Route
            path="/login"
            element={
              loggedInUser ? (
                <Navigate to="/" />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />
          {loggedInUser ? (
            <>
              <Route path="/" element={<UploadExcel />} />
              <Route path="/tweetTable" element={<TweetTable />} />
              <Route path="/trendingHashtags" element={<TrendingHashtags />} />
              <Route path="/edit/:tweetId" element={<EditTweet />} />
            </>
          ) : (
            <Route path="/*" element={<Navigate to="/login" />} />
          )}
        </Routes>
      </div>
    </Router>
  );
};

export default App;