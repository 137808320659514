
import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Logo from './Assets/logo.svg';
import './Navbar.css'; 
const apiUrl = process.env.REACT_APP_API_URL 

const NavigationBar = ({ onLogout }) => {
  const [logout, setLogout] = useState(false); 

  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/admin/logout`, {
        method: 'POST',
      });

      if (response.ok) {
       
        setLogout(true);
        onLogout(); 
      } else {
        console.error('Logout failed:', response.statusText);
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  if (logout) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div className="header-main">
        <div>
          <img src={Logo} alt="Logo" id="logo" />
        </div>
        <div className="navbar">
          <Link to="/" className="nav-btn" activeClassName="active">
            Upload Excel
          </Link>
          <Link to="/tweetTable" className="nav-btn" activeClassName="active">
            Tweet Table
          </Link>
          <Link to="/trendingHashtags" className="nav-btn" activeClassName="active">
            Trending Hashtags
          </Link>
          {/* Logout button */}
          <button onClick={handleLogout} className="nav-btn logout-btn">
            <i className="fa fa-sign-out"></i> Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default NavigationBar;