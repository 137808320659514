// Login.js
import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';
import Waves from "./Assets/layered-waves-haikei_1.svg"



const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState(null);

  const handleLogin = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL 
    
    const response = await axios.post(`${apiUrl}/api/v1/admin/login`, {
      username: username,
      password: password,
    });

    
      if (response.data.success) {
        onLogin(username);
        setLoginStatus({ type: 'success', message: 'Login successful.' });
      } else {
        setLoginStatus({ type: 'error', message: 'Invalid credentials. Please try again.' });
      }
    } catch (error) {
      console.error('Error during login:', error);
      setLoginStatus({ type: 'error', message: 'An error occurred during login. Please try again.' });
    }
  };

  return (
    <div className='login-page'>
          <div className="login-container">
      <h2>Login</h2>
      <div className='data'>
        <label>Username:</label>
        <input className='user' type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </div>
      <div className='data'>
        <label >Password:</label>
        <input className='user' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <button  onClick={handleLogin}>Login</button>

      {loginStatus && (
        <div className={loginStatus.type === 'success' ? 'success-msg' : 'error-msg'}>
          {loginStatus.message}
        </div>
      )}
    </div>

    <img className="image" src={Waves}/>

    </div>



  );
};

export default Login;