import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './trendingTags.css'
const apiUrl = process.env.REACT_APP_API_URL 





const TrendingHashtags = ({ onHashtagClick }) => {
    const [trendingHashtags, setTrendingHashtags] = useState([]);
    const [newHashtags, setNewHashtags] = useState('');
    const [error, setError] = useState('');
  
    useEffect(() => {
      fetchTrendingHashtags();
    }, []);
  
    const fetchTrendingHashtags = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/tweets/getAllTrendingHashtags`);
        setTrendingHashtags(response.data.hashtags);
      } catch (error) {
        console.error('Error fetching trending hashtags:', error);
      }
    };
  
    const handleEditUpdate = async () => {
      if (newHashtags.length > 50) {
        setError('Hashtags should not exceed 50 characters.');
        return;
      }
  
      try {
        const response = await axios.put(`${apiUrl}/api/v1/tweets/editUpdateHashtags`, {
          tags: newHashtags.split(',').map((tag) => tag.trim()), 
        });
  
        if (response.data.success) {
          setTrendingHashtags(response.data.newTags);
          setNewHashtags('');
          setError('');
        } else {
          console.error('Error updating hashtags:', response.data.error);
        }
      } catch (error) {
        console.error('Error updating hashtags:', error);
      }
    };
  
    return (
      <div style={{ marginTop: '50px', textAlign: 'center' }}>
        <div>
          <h2>Trending Hashtags</h2>
          <ul>
            {trendingHashtags.map((hashtag, index) => (
              <li key={index} onClick={() => onHashtagClick(hashtag)}>
                {hashtag}
              </li>
            ))}
          </ul>
        </div>
        <div style={{ marginTop: '20px' }}   className="inputbox" >
          <h3>Edit/Update Hashtags</h3>
          <input
            type="text"
            placeholder="Enter hashtags (comma-separated)"
            value={newHashtags}
            onChange={(e) => {
              setNewHashtags(e.target.value);
              setError(''); // Clear error when user starts typing
            }}
          />
          <button className="updatebutton" onClick={handleEditUpdate}>Update Hashtags</button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      </div>
    );
  };
  
  export default TrendingHashtags;