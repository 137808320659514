// EditTweet.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './editform.css'
const apiUrl = process.env.REACT_APP_API_URL 

const EditTweet = ({ tweetId, onCancel, onUpdate }) => {
  const [editFormData, setEditFormData] = useState({
    description: '',
    tags: '',
    tweetCount: 0,
  });

  useEffect(() => {

    const fetchTweet = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/tweets/getTweet/${tweetId}`);
      
        const tweetToEdit = response.data.tweet;
        
        setEditFormData({
          description: tweetToEdit.description,
          tags: tweetToEdit.tags.join(', '),
          tweetCount: tweetToEdit.tweetCount,
        });
      } catch (error) {
        console.error('Error fetching tweet for editing:', error);
      }
    };

    fetchTweet();
  }, [tweetId]);

  const handleEditFormChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    try {
      
      const response = await axios.put(
        `${apiUrl}/api/v1/tweets/editTweet/${tweetId}`,
        editFormData
      );

  
      onUpdate(response.data.tweet);

      // Close the form
      onCancel();
    } catch (error) {
      console.error('Error updating tweet:', error);
    }
  };

  return (
    <div className='editMain'>
      <h2>Edit Tweet</h2>
      <form onSubmit={handleEditSubmit}>
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          name="description"
          value={editFormData.description}
          onChange={handleEditFormChange}
        />
        <label htmlFor="tags">Tags:</label>
        <input
          type="text"
          id="tags"
          name="tags"
          value={editFormData.tags}
          onChange={handleEditFormChange}
        />
        <label htmlFor="tweetCount">Tweet Count:</label>
        <input
          type="number"
          id="tweetCount"
          name="tweetCount"
          value={editFormData.tweetCount}
          onChange={handleEditFormChange}
        />
        <button type="submit">Update Tweet</button>
      </form>
    </div>
  );
};

export default EditTweet;
