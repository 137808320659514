import { FaSave, FaTimes, FaEdit, FaTrash } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./tweetTable.css";
import './Assets/load-8510_128.gif'
import DeleteConfirmationPopup from "./deletetweet";

const TweetTable = ({ loggedInUser }) => {
  const [tweets, setTweets] = useState([]);
  const [editedTweet, setEditedTweet] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [deletePopupPosition, setDeletePopupPosition] = useState({ x: 0, y: 0 });


  useEffect(() => {
    fetchAllTweets(currentPage);
  }, [currentPage]);

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchAllTweets = async (currentPage) => {
    try {
      setLoading(true); // Set loading state to true before fetching
      const response = await axios.get(
        `${apiUrl}/api/v1/tweets/alltweets`,
        {
          params: { page: currentPage },
        }
      );
      setTweets(response.data.tweets);
    } catch (error) {
      console.error("Error fetching tweets:", error);
    } finally {
      setLoading(false); // Set loading state back to false regardless of success or error
    }
  };

  const handlePageChange = (pageNumber) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setCurrentPage(pageNumber);
    }, 1000);
  };

  const handleEdit = (tweetId) => {
    setEditedTweet(tweetId);
  };

  const handleCancelEdit = () => {
    setEditedTweet(null);
  };

  const handleUpdateTweet = async (updatedTweet) => {
    try {
      const response = await axios.put(
        `${apiUrl}/api/v1/tweets/updateTweet/${updatedTweet._id}`,
        updatedTweet
      );

      const updatedTweetData = response.data.tweet;

      setTweets((prevTweets) =>
        prevTweets.map((tweet) =>
          tweet._id === updatedTweetData._id ? updatedTweetData : tweet
        )
      );

      setEditedTweet(null);
    } catch (error) {
      console.error("Error updating tweet:", error);
    }
  };

  const handleDeleteConfirmation = (tweetId, position) => {
    setDeleteConfirmation(tweetId);
    setDeletePopupPosition(position);
  };
  const handleCancelDelete = () => {
    setDeleteConfirmation(null);
  };

  const handleDelete = async (tweetId) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/api/v1/tweets/deleteTweet/${tweetId}`
      );
      const deletedTweet = response.data.tweet;

      setTweets((prevTweets) =>
        prevTweets.filter((tweet) => tweet._id !== tweetId)
      );

      console.log(
        "Delete tweet with ID:",
        tweetId,
        "Deleted Tweet:",
        deletedTweet
      );

      setDeleteConfirmation(null); // Close confirmation dialog after successful deletion
    } catch (error) {
      console.error("Error deleting tweet:", error);
    }
  };

  return (
    <div style={{ marginTop: "50px", textAlign: "center", padding: "4px" }}>
      <h2 className="tweet">Tweet Table</h2>
      <table className="tweet-table">
        <thead>
          <tr>
            <th>Description</th>
            <th>Tags</th>
            <th>TweetCount</th>
            <th>Upload Timestamp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tweets.map((tweet) => (
            <tr key={tweet._id}>
              <td style={{ color: "#000" }}>
                {editedTweet === tweet._id ? (
                  <input
                    type="text"
                    value={tweet.description}
                    onChange={(e) =>
                      setTweets((prevTweets) =>
                        prevTweets.map((t) =>
                          t._id === tweet._id
                            ? { ...t, description: e.target.value }
                            : t
                        )
                      )
                    }
                  />
                ) : (
                  tweet.description
                )}
              </td>
              <td style={{ color: "#000" }}>{tweet.tags.join(", ")}</td>
              <td style={{ color: "#000" }}>{tweet.tweetCount}</td>
              <td style={{ color: "#000" }}>
                {new Date(tweet.uploadTimestamp).toLocaleString()}
              </td>
              <td>
                {editedTweet === tweet._id ? (
                  <>
                    <FaSave
                      className="action-icon"
                      onClick={() => handleUpdateTweet(tweet)}
                      title="Save"
                    />
                    <FaTimes
                      className="action-icon"
                      onClick={handleCancelEdit}
                      title="Cancel"
                    />
                  </>
                ) : (
                  <FaEdit
                    className="action-icon"
                    onClick={() => handleEdit(tweet._id)}
                    title="Edit"
                  />
                )}
                <FaTrash
                  className="action-icon"
                  onClick={() => handleDeleteConfirmation(tweet._id)}
                  title="Delete"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && (
        <div className="loader">Loading...</div>
      )}
      {deleteConfirmation && (
        <div className="confirmation-dialog">
          <p>Are you sure you want to delete this tweet?</p>
          <div>
            <button onClick={() => handleDelete(deleteConfirmation)}>Delete</button>
            <button onClick={handleCancelDelete}>Cancel</button>
          </div>
        </div>
      )}
      <div style={{ marginTop: "20px" }}>
        <button
          className="page-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1 || loading}
        >
          Previous Page
        </button>
        <span style={{ margin: "0 10px" }}>Page {currentPage}</span>
        <button
          className="page-button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={loading}
        >
          Next Page
        </button>
      </div>
    </div>
  );
};

export default TweetTable;